//
// Main
//


body {
	background-color: var(--kt-page-bg);
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}
.customercard{
	position: relative;
	height: 285px;
	 width: 455px;
}

.customeridcode {
    position: absolute;
    width: 100%;
    /* bottom: 135px; */
    color: white;
    font-size: 27px;
    font-weight: bold;
    text-transform: uppercase;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.customermobile {
    position: absolute;
    width: 100%;
    bottom: 80px;
    color: rgb(253, 0, 2);
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customername {
    position: absolute;
    width: 100%;
    bottom: 115px;
    color: rgb(253, 0, 2);
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
}

table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
  }
  
  td, th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
  }
  
  tr:nth-child(even) {
	background-color: #dddddd;
  }
  .text-green{
	color: green;
  }

  .pclass1{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri;,sans-serif';
  }
  .sclass1{
	font-size:19px;
	line-height:115%;
  }
  .pclass2{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri,sans-serif';
	text-align:center;
  }
  .sclass2{
	font-size:37px;
	line-height:115%;
	color:#C00000;
  }

  .pclassComm{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri&quot;,sans-serif';
	text-align:'justify';
  }

  .pclassComm2{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri&quot;,sans-serif';
  }

  .pclassComm3{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri&quot;,sans-serif';
	text-align:center;
  }

  .pclassComm4{
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:15px;
	font-family:'Calibri;,sans-serif';
	text-align:center;
	text-indent:-1.65pt;

  }

  .hide{
	display: none;
  }

  .pclassComm5{
	margin: 0cm 0cm 10pt 324pt; 
	line-height: 115%; 
	font-size: 15px; 
	font-family: 'Calibri, sans-serif';
	 text-align: center; 
	 text-indent: 36pt;
  }

  .spaCommo{
	font-size:19px;
	line-height:115%;
  }

  .spaCommo1{
	font-size: 28px; 
	line-height: 115%;
  }
  .fontColor{
	color: white;
  }
// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		padding-left: get($aside-config, width, desktop);	

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, default, height, desktop);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile);
		}
	}
	
}
